import type { BaseData, NormalProgrammeParams } from '../../utils';
import { request, MainSubStructureModel } from '../../utils';
import { observable } from 'mobx';
import { Button, Modal, message } from 'antd';
import React from 'react';
import DemandEditorModel from './modal/model';
import { API } from './api';

const { confirm } = Modal;

export default class Model {
  constructor() {
    this.mainSubStructureModel?.gridModel?.onQuery();
  }

  public editorStore = new DemandEditorModel({ parent: this });

  public mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    buttons: [
      {
        text: '新增',
        handleClick: () => {
          this.editorStore.onShow();
        },
      },
    ],
    grid: {
      columns: [
        {
          key: 'id',
          name: '操作',
          width: 150,
          formatter: ({ row }) => (
            <div>
              <Button
                onClick={() => {
                  this.editorStore.onShow(row, 'edit');
                }}
                type="link"
              >
                编辑
              </Button>
              <Button
                onClick={() => {
                  this.onDelete(row);
                }}
                type="link"
              >
                删除
              </Button>
            </div>
          ),
        },
        {
          key: 'targetSequence',
          name: '序号',
          width: 240,
        },
        {
          key: 'targetName',
          name: '需求名称',
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      showRefresh: false,
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };
        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        return request<BaseData<any>>({
          url: API.queryDemandPage,
          method: 'POST',
          data: { ...postParams },
        });
      },
    },
  });

  // 删除表格项
  private onDelete = (row) => {
    confirm({
      title: `删除提示`,
      content: `确定要删除需求「${row.targetName}」吗?`,
      okText: '确定',
      cancelText: '取消',
      onOk: async () => {
        const result = await request({
          url: API.deleteDemand,
          method: 'POST',
          data: { ids: row.id },
        });
        message.success('删除成功');
        this.mainSubStructureModel?.gridModel?.onQuery();
      },
    });
  };
}
