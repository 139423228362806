import { Button, Col, Form, Input, Modal, Row, Space, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';
import type Model from './model';

// 新增或编辑需求配置信息
export const DemandEditorModal = observer(({ store }: { store: Model }) => {
  if (!store) {
    return null;
  }
  const { visible, onClose, saveLoading, onSave, setRef } = store;
  return (
    <Modal
      centered
      footer={
        <Space>
          <Button onClick={onClose}>取消</Button>
          <Button
            loading={saveLoading}
            onClick={onSave}
            type="primary"
          >
            保存
          </Button>
        </Space>
      }
      maskClosable={false}
      onCancel={onClose}
      open={visible}
      title="需求配置"
    >
      <Form ref={setRef}>
        <Row>
          <Col span={24}>
            <Form.Item
              label="序号"
              name="targetSequence"
              rules={[{ required: true }]}
              validateTrigger="onBlur"
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                max={999999}
                autoComplete="off"
                placeholder="请输入序号,数字越小越靠前"
                maxLength={6}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label="需求"
              name="targetName"
              validateTrigger="onBlur"
              rules={[{ required: true }]}
            >
              <Input
                autoComplete="off"
                placeholder="请输入需求名称"
                maxLength={10}
                showCount
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
});
