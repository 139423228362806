export const API = {
  // 查询需求配置
  queryDemandPage: '/quan/biz/target/list/page',
  // 新增需求配置
  addDemand: '/quan/biz/target/add',
  // 编辑需求配置
  editDemand: '/quan/biz/target/edit',
  // 删除需求配置
  deleteDemand: '/quan/biz/target/delete',
};
